<template>
  <div>
    <b-button
      type="submit"
      variant="primary"
      :to="`/tracker/detail/${uuid}`"
      size="md"
      class="mb-1"
    >
      <feather-icon
        icon="ArrowLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">{{$t('Labels.Back')}}</span>
    </b-button>
    <b-tabs>
      <b-tab active>
        <template #title>
          <feather-icon class="mr-1" icon="ListIcon" /> <span>{{$t('Labels.State')}}</span>
        </template>
        <b-card :title="$t('Labels.LatestData')">
          <b-row class="container-filters-default" align-v="center">
            <b-col sm="12" md="auto" class="text-center my-2">
              <label>{{$t('Labels.OrderBy')}}</label>
              <v-select @input="getResponse" :options="columnOrders" v-model="filters.byColumn" :reduce="v => v.value"  :clearable="false"/>
            </b-col>
            <b-col sm="12" md="auto" class="text-center my-2">
              <b-button size="sm" @click="filterOrders('ASC')"  variant="flat-secondary" class="btn-order" :class="{'bg-primary': filters.byOrder == 'ASC'}">
                <iconBase iconName="filter_asc" :width="18" :height="18" iconColor="#626262" class="align-middle"><iconFilterAsc/></iconBase>
              </b-button>
              <b-button  size="sm" @click="filterOrders('DESC')" variant="flat-secondary" class="btn-order" :class="{'bg-primary': filters.byOrder != 'ASC'}">
                <iconBase iconName="filter_desc" :width="18" :height="18" iconColor="#626262" class="align-middle"><iconFilterDesc/></iconBase>
              </b-button>
            </b-col>
            <b-col sm="12" md="auto" class="text-center my-2">
              <label>{{$t('Labels.ByPage')}}</label>
              <v-select @input="getResponse" class="align-middle inline-block" :options="byPage" v-model="filters.limit" :reduce="v => v.value"  :clearable="false"/>
            </b-col>
            <b-col sm="12" md="auto" class="text-center my-2"><!--v-if="urlDownload.length > 0"-->
              <a v-if="urlDownload.length > 0" class="btn btn-order btn-flat-secondary btn-sm text-primary align-middle" :href="urlDownload" download="Tracker.csv">
                <feather-icon icon="DownloadCloudIcon" size="20" />
              </a>
            </b-col>
          </b-row>
          <b-table hover :fields="fields" :items="response" :busy="isBusy" :responsive="true" thead-class="d-none" tbody-tr-class="shadow-sm" table-variant="light">
            <template #cell(created_at)="item">
              <listItem
                :value="item.value"
                :description="$t('Labels.Date')"
                img="fecha"
              />
            </template>
            <template #cell(lat)="item">
              <listItem
                :value="item.value"
                :description="$t('Labels.Latitude')"
                img="georeference"
              />
            </template>
            <template #cell(long)="item">
              <listItem
                :value="item.value"
                :description="$t('Labels.Longitude')"
                img="georeference"
              />
            </template>
            <template #cell(battery)="item">
              <listItem
                :value="batteryCalculate(item.value)"
                :description="$t('Labels.Battery')"
                img="battery"
                unit="%"
              />
            </template>
            <template #cell(tipo_alerta)="item">
              <alertTracker :status="item.value" />
            </template>
          </b-table>
          <b-pagination v-if="response.length > 0" :per-page="filters.limit" :total-rows="totalRows" v-model="currentx" align="right"/>
          <div v-else class="noData">{{$t('Messages.NoData')}}</div>
        </b-card>
      </b-tab>
      <b-tab>
        <template #title>
          <feather-icon class="mr-1" icon="ActivityIcon" /> <span>{{$t('Labels.Graphics')}}</span>
        </template>
        <b-card>Tab contents 2</b-card>
      </b-tab>
    </b-tabs>
  </div>
</template>
<script>
import {
  BRow,
  BCol,
  BCard,
  BButton,
  BTabs,
  BTab,
  BTable,
  BPagination
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import iconBase from '@core/spore-components/icons/iconBase'
import iconFilterAsc from '@core/spore-components/icons/iconFilterAsc'
import iconFilterDesc from '@core/spore-components/icons/iconFilterDesc'
import listItem from '@core/spore-components/listItem/listItem.vue'
import { relativeTimeConvert, batteryCalculate, getAlertMessage } from '@/libs/tools/helpers'
import request from '@/libs/request/index'
import alertTracker from '@core/spore-components/alertTracker/alertTracker.vue'

const DATALOG = {
  code: 200,
  data: {
    count: 2,
    rows: [
      {
        uuid: '25288d16-d980-4f4c-883c-7164117554eb',
        name: 'Pibi',
        imei: '010203040506070809',
        deveui: '1234567890',
        lat: '20.67945688701759',
        long: '103.36572719122735',
        tipo_alerta: 'C',
        battery: '50',
        movement: '0',
        enabled_notifications: '0',
        time_notifications: '5',
        created_at: '2021-09-15 22:09:41',
        updated_at: '2021-09-15 22:09:41',
        user_uuid: null,
        satellites: '0',
        rssi: '-65',
        latlong_alert_string: '20.67945688701759, -103.36572719122735',
        radius_alert: '100',
        alert_in_radius: 'O',
        alert_polygon: true,
        user: null
      },
      {
        uuid: '25288d16-d980-4f4c-883c-7164117554eb',
        name: 'Pibi',
        imei: '010203040506070809',
        deveui: '1234567890',
        lat: '20.67945688701759',
        long: '103.36572719122735',
        tipo_alerta: 'C',
        battery: '50',
        movement: '0',
        enabled_notifications: '0',
        time_notifications: '5',
        created_at: '2021-09-15 22:09:41',
        updated_at: '2021-09-15 22:09:41',
        user_uuid: null,
        satellites: '0',
        rssi: '-65',
        latlong_alert_string: '20.67945688701759, -103.36572719122735',
        radius_alert: '100',
        alert_in_radius: 'O',
        alert_polygon: true,
        user: null
      }
    ]
  },
  msg: 'Ok'
}
export default {
  components: {
    BRow,
    BCol,
    BCard,
    BButton,
    BTabs,
    BTab,
    'v-select': vSelect,
    iconBase,
    iconFilterAsc,
    iconFilterDesc,
    BTable,
    listItem,
    BPagination,
    alertTracker
  },
  data () {
    return {
      currentx: 1,
      totalRows: 0,
      filters: {
        limit: 20,
        offset: 0,
        byColumn: 'created_at',
        byOrder: 'ASC'
      },
      columnOrders: [
        { label: this.$t('Labels.Date'), value: 'created_at' }
      ],
      byPage: [
        { label: 20, value: 20 },
        { label: 50, value: 50 },
        { label: 75, value: 75 },
        { label: 100, value: 100 }
      ],
      uuid: this.$route.params.uuid,
      urlDownload: '',
      fields: [
        { key: 'created_at', label: this.$t('Labels.Date') },
        { key: 'lat', label: this.$t('Labels.Latitude') },
        { key: 'long', label: this.$t('Labels.Longitude') },
        { key: 'battery', label: this.$t('Labels.Battery') },
        { key: 'tipo_alerta', label: this.$t('Labels.Alert') }
      ],
      response: [],
      isBusy: false,
      DATALOG,
      batteryCalculate
    }
  },
  methods: {
    async getResponse () {
      this.isBusy = true
      const params = {
        url: `tracker/${this.uuid}/log`,
        method: 'GET',
        params: this.filters
      }
      await request(params).then(response => {
        this.response = response.data.data.rows
        this.totalRows = response.data.data.count
        this.createCsv()
      }).catch(() => {
        this.response = []
        this.setPages(0)
      }).finally(() => {
        this.isBusy = false
      })
    },
    createCsv () {
      const csv = []
      const headers = []
      headers.push(this.$t('Labels.Date'))
      headers.push(this.$t('Labels.LastSeen'))
      headers.push(this.$t('Labels.Latitude'))
      headers.push(this.$t('Labels.Longitude'))
      headers.push(this.$t('Labels.Battery'))
      headers.push(this.$t('Labels.Status'))
      csv.push(headers.join(','))
      this.response.map(item => {
        const row = []
        row.push(item.created_at)
        row.push(relativeTimeConvert(item.created_at))
        row.push(item.lat)
        row.push(item.long)
        row.push(batteryCalculate(item.battery) + '%')
        row.push(getAlertMessage(item.tipo_alerta))
        csv.push(row.join(','))
        return item
      })
      const file = csv.join('\n')
      const csvFile = new Blob([file], { type: 'text/csv' })
      this.urlDownload = window.URL.createObjectURL(csvFile)
    },
    filterOrders (param) {
      this.filters.byOrder = param
      this.getResponse()
    }
  },
  created () {
    this.getResponse()
  },
  watch: {
    currentx (val) {
      this.filters.offset = (val - 1) * this.filters.limit
      this.getResponse()
    },
    byColumn (val) {
      this.filters.byColumn = val
      this.currentx = 1
    }
  }
}
</script>
<style lang="scss" scoped>
  @import "@/assets/scss/pages/Statistics.scss";
</style>
